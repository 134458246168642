import along from '@turf/along'
import bearing from '@turf/bearing'
import destination from '@turf/destination'
import turfLength from '@turf/length'
import { lineString } from '@turf/helpers'
import { Constants } from '@/libs/map-draw/lib-utils'
import {
  getFeaturesByLayerId,
  getStopPointsData,
  createStopPointsHelpers,
  nearestPointOnLine,
  isPointInLinkArea
} from '@/libs/map-draw/helpers'

export const createODDDrawSignsMode = (MapboxDraw, map) => {
  const DrawSigns = {}
  const customState = {
    point: null
  }

  const { ids } = map.$store.state.model

  DrawSigns.onTap = DrawSigns.onClick = function (state, e) {
    const {
      point
    } = customState
    const { lng, lat } = e.lngLat
    const nodesFeatures = getFeaturesByLayerId(this.map, e, ids.nodes, 50)
    const linksFeatures = getFeaturesByLayerId(this.map, e, ids.links, 100)
    const racksFeatures = getFeaturesByLayerId(this.map, e, 'racks', 10)
    const pointFeatures = [...racksFeatures, ...nodesFeatures]

    if (pointFeatures.length) {
      let length = Infinity
      let feature = pointFeatures[0]

      for (let i = 0; i < pointFeatures.length; i++) {
        const node = pointFeatures[i]
        const line = lineString([[lng, lat], node.geometry.coordinates])
        const lineDistance = turfLength(line, { units: 'kilometers' })

        if (lineDistance < length) {
          length = lineDistance
          feature = pointFeatures[i]
        }
      }

      const { coordinates } = feature.geometry
      const projectionPoint = {
        type: 'Point',
        coordinates
      }

      // set properties
      state.point.updateCoordinate('', coordinates[0], coordinates[1])
      state.point.setProperty('node_id', feature.properties.id)
      state.point.setProperty('sign_icon_id', null)
      state.point.setProperty('name', 'Новый знак')
      state.point.setProperty('projection', projectionPoint)
      state.point.setProperty('point', point)

      //  removeStopPointsHelpers(this.map)
      this.map.fire(Constants.events.CREATE, {
        features: [state.point.toGeoJSON()]
      })

      // change mode
      this.changeMode(Constants.modes.SIMPLE_SELECT, {
        featureIds: [state.point.id]
      })

      map.$store.commit('SET', ['editorState.mode', 'select'])
    } else if (linksFeatures.length) {
      const link = linksFeatures.find(l =>
        isPointInLinkArea([lng, lat], l.geometry.coordinates, 100)
      )

      if (link) {
        // removeHoveredSPHelpers(this.map)
        const nearest = nearestPointOnLine(
          [lng, lat],
          link.geometry.coordinates
        )
        const current = {
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          properties: {
            projection: 'true'
          }
        }

        const options = { units: 'kilometers' }
        const angle = bearing(nearest.geometry, current.geometry)
        const destinationPoint = destination(nearest, 0.02, angle, options)
        const destinationLine = [
          nearest.geometry.coordinates,
          destinationPoint.geometry.coordinates
        ]

        const point = along(lineString(destinationLine), 0.02, options)

        const { id } = link.properties

        // set properties
        state.point.updateCoordinate(
          '',
          point.geometry.coordinates[0],
          point.geometry.coordinates[1]
        )
        state.point.setProperty('link_id', id)
        state.point.setProperty('sign_icon_id', null)
        state.point.setProperty('name', 'Новый знак')
        state.point.setProperty('projection', nearest.geometry)
        state.point.setProperty('point', point)

        customState.currentPoint = current.geometry

        const connection = {
          geometry: {
            type: 'LineString',
            coordinates: [nearest.geometry.coordinates, [lng, lat]]
          }
        }
        console.log(connection)
        const data = getStopPointsData(nearest, current, connection)
        // const data = getStopPointsData(nearest, point, {
        //   geometry: {
        //     type: 'LineString',
        //     coordinates: [
        //       nearest.geometry.coordinates,
        //       point.geometry.coordinates
        //     ]
        //   }
        // })
        console.log(data)
        createStopPointsHelpers(this.map, data)

        this.map.fire(Constants.events.CREATE, {
          features: [state.point.toGeoJSON()]
        })

        // change mode
        this.changeMode(Constants.modes.SIMPLE_SELECT, {
          featureIds: [state.point.id]
        })

        map.$store.commit('SET', ['editorState.mode', 'select'])
      }
    }
  }

  DrawSigns.onMouseMove = function(state, e) {
    customState.point = e.point
  }

  return { ...MapboxDraw.modes.draw_point, ...DrawSigns }
}
